import React,{useState,useContext,useEffect} from "react";
import { styled, alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import {ToastContainer,toast} from 'react-toastify'

//drawer elements used
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InputBase from "@mui/material/InputBase";      
import SearchIcon from "@mui/icons-material/Search";
import PortraitIcon from '@mui/icons-material/Portrait';
import LogoutIcon from '@mui/icons-material/Logout';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { AccountCircle, Article, SportsSoccer, StarBorder } from "@mui/icons-material";
import WidgetsIcon from '@mui/icons-material/Widgets';
import { FeaturesContext, UserContext } from "../../contexts/userContext";
import { useNavigate } from "react-router-dom";
import axios from "../../axios/axios"
import './menu-drawer.css'
  

const StyledSearch = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  }));
  
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch"
      }
    }
  }));
  
  

function MenuDrawer(props) {
    const {close,profileOpen,logout,openLogin,navMatches} = props
    const [isExpanded, setIsExpanded] = useState(false);
    const [isExpanded2, setIsExpanded2] = useState(false);
    const {userData} = useContext(UserContext)
    const navigate = useNavigate()
    const {competitions, setCompetitions} = useContext(FeaturesContext);
    const [isCompetition, setIsCompetition] = useState(true);
    const [subCompetitions, setSubCompetitions] = useState([]);
    const [competitionTitle, setCompetitionTitle] = useState('')
    const [fullList, setFullList] = useState(false);
    const [fullStages, setFullStages] = useState([])
    const [searchResults, setSearchResults] = useState([])
    


    const handleSearch =(value)=>{
        if(value!==''){
        setCompetitions([])
        const result = fullStages.filter(comp=>{
            return comp[1].toLowerCase().includes(value.toLowerCase())
        })
        setSearchResults(result)
        }else{  
        setSearchResults([])
        setCompetitions(prevValue=>[...prevValue,...fullStages.slice(0, 25)])
        }
    }

    const goToCompetitionOverview=(competition,compName)=>{
        const competitionUrl=`${process.env.REACT_APP_COMPETITION_URL}/${competition}/5.30?MD=1`
        try {
        axios.get(`/competition-overview`,{
            params:{url:competitionUrl}
        }).then(res=>{
            navigate(`/competition-overview/${compName}`,{state:{overview:res.data}})
        })
        } catch (error) {
        toast(error.message)
        }
    }

    const fetchData = () => {
        axios.get(`/competitions-list`).then((res) => {
        setFullStages(res.data)
        if (fullList) {
            setCompetitions(res.data);
        } else {
            setCompetitions(res.data.slice(0, 25));
        }
        }).catch(()=>{
        fetchData()
        })
    };

    const getSubCompetitions = (subCompetition) => {
        axios
        .get(`/sub-competitions`, {
            params: { subCompetition: subCompetition },
        })
        .then((res) => {
            setSubCompetitions(res.data);
            setIsCompetition(false);
        });
    };

    useEffect(() => {
        fetchData();
    }, [fullList]);

    const toggleAccordion = () => {
        setIsExpanded(!isExpanded);
        setIsExpanded2(false)
    };

    const toggleAccordion2 = () => {
        setIsExpanded2(!isExpanded2);
        setIsExpanded(false)
    };

    //search as JSX
    const search = (
        <StyledSearch sx={{marginLeft:'15px'}}>
        <SearchIconWrapper>
            <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search"}}
            onChange={(e)=>{
                handleSearch(e.target.value)
            }}
        />
        </StyledSearch>
    );
 

  return (
    <>
    <Box
    sx={{
        p: 2,
        height: 1,
        backgroundColor: "#dbc8ff",
    }}
    >
    {/* when clicking the icon it calls the function toggleDrawer and closes the drawer by setting the variable open to false */}
    <IconButton sx={{ mb: 2 ,width:'40px'}}>
        <CloseIcon onClick={close(false)} />
    </IconButton>  

    <Divider sx={{ mb: 2 }} />

    <Box sx={{ mb: 2 }}>
        <div className="profile-accordion">
            <ListItemButton onClick={toggleAccordion} >
            <ListItemIcon>
                <AccountCircle sx={{ color: "primary.main" }} />
            </ListItemIcon>
            <ListItemText  primary={userData?userData.name:'User'} />
            <span>{isExpanded ? '▼' : '➤'}</span>
            </ListItemButton>
        </div>
        {isExpanded?userData?(<>
            <ListItemButton onClick={profileOpen} sx={{background:'white'}}>
            <ListItemIcon>
                <PortraitIcon sx={{ color: "primary.main" }} />
            </ListItemIcon>
            <ListItemText primary="Profile" />   
            </ListItemButton>
            <ListItemButton onClick={logout} sx={{background:'white',borderBottom:'1px solid darkgray'}}>
            <ListItemIcon>
                <LogoutIcon sx={{ color: "primary.main" }} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
            </ListItemButton>
        </>):(
             <ListItemButton onClick={openLogin} sx={{background:'white',borderBottom:'1px solid darkgray'}}>
             <ListItemIcon>
                 <ExitToAppIcon sx={{ color: "primary.main" }} />
             </ListItemIcon>
             <ListItemText primary="Login" />
             </ListItemButton>
        ):''}
        <div className="browse-accordion">
            <ListItemButton onClick={toggleAccordion2} >
            <ListItemIcon>
                <WidgetsIcon sx={{ color: "primary.main" }} />
            </ListItemIcon>
            <ListItemText  primary={'Browse'} />
            <span>{isExpanded2 ? '▼' : '➤'}</span>
            </ListItemButton>
        </div>
        {isExpanded2 && <>
            <ListItemButton onClick={()=>navigate('/')} sx={{background:'white'}}>
            <ListItemIcon>
                <SportsSoccer sx={{ color: "primary.main" }} />
            </ListItemIcon>
            <ListItemText primary="Scores" />   
            </ListItemButton>
            <ListItemButton onClick={()=>navMatches.length!==0?navigate('/favorites',{ state: { navMatches }}):toast('please wait...')} sx={{background:'white'}}>
            <ListItemIcon>
                <StarBorder sx={{ color: "primary.main" }} />
            </ListItemIcon>
            <ListItemText primary="Favorites" />   
            </ListItemButton>
            <ListItemButton onClick={()=>navigate('/news')} sx={{background:'white',borderBottom:'1px solid darkgray'}}>
            <ListItemIcon>
                <Article sx={{ color: "primary.main" }} />
            </ListItemIcon>
            <ListItemText primary="News" />   
            </ListItemButton>
        </>}
    </Box>


    {isCompetition ? search: (
        <> 
        <div style={{display:'flex'}}>
        <h1 onClick={() => setIsCompetition(true)}>&lt;</h1>
        <span style={{margin:'auto'}}>{competitionTitle}</span>
        </div>                                 
        </>
    )}
    <hr />  

    <div style={{maxWidth:'235px',marginLeft:'15px',overflowY:'scroll',overflowX:'hidden'}}>
      
      {isCompetition ? (    
        <>
          {competitions.length!==0?competitions.map((competition, i) => (
            <div
              className="list-container"
              key={i}
              onClick={() => {
                getSubCompetitions(competition[0]);
                setCompetitionTitle(competition[1])
              }}   
            >
              <img
                className="list-logo"
                src={
                  "https://static.livescore.com/i2/fh/" +
                  competition[3] +
                  ".jpg"
                }
                alt=""
              />
              <span className="list-names">{competition[1]}</span>
            </div>
          )):''}
          {searchResults.length!==0?searchResults.map((competition, i) => (
            <div
              className="list-container"
              key={i}
              onClick={() => {
                getSubCompetitions(competition[0]);
                setCompetitionTitle(competition[1])
              }}
            >
              <img
                className="list-logo"
                src={
                  "https://static.livescore.com/i2/fh/" +
                  competition[3] +
                  ".jpg"
                }
                alt=""
              />
              <span className="list-names">{competition[1]}</span>
            </div>
          )):''}
        </>
      ) : (     
        <>
          {subCompetitions.map((competition, i) => (
            <div
              className="list-container" key={i} onClick={() => {
                goToCompetitionOverview(competition[2].substring(4,competition[2].length-1),competition[0])
              }}>
              <img className="list-logo" src={"https://static.livescore.com/i2/fh/" +competition[3] +".jpg"} alt=""/>
              <span className="list-names">{competition[1]}</span>
            </div>
          ))}
        </>
      )}
      {isCompetition && !fullList?(
        <>
        <hr/>
        <p style={{textAlign:"center",lineHeight:0}} onClick={()=>{
            setFullList(true)
        }}>View all</p>
        </>
        ):isCompetition && fullList?(
        <>
        <hr/>
        <p style={{textAlign:"center",lineHeight:0}} onClick={()=>{
            setFullList(false)
        }}>View less</p>
        </>
        ):''
      }
    </div>
    </Box>
    <ToastContainer/>
    </>
  );
}

export default MenuDrawer;
