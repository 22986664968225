import React from 'react'
import './line-ups.css'

function LineUps({lineUpsData}) {
  return (
    <>
    {lineUpsData.length!==0?(
        <>
        {lineUpsData.lineupMessage?.text==="lineups.confirmedLineup"?<span style={{lineHeight:0}}><img src={`https://www.livescore.com/ls-web-assets/${lineUpsData.lineupMessage.icon.src}.svg`} alt="" /> Confirmed Line-up</span>:''}
        
        <div className='pitch'>
            {lineUpsData.fieldData && <span style={{marginLeft:'31px'}}>{lineUpsData.fieldData.homeTeamName+' '+lineUpsData.fieldData.homeFormation}</span>}
            {lineUpsData.fieldData && <div className='field'>
                {/* hometeam */}
                <div style={{height:'100%',width:'50%',display:'flex'}}>
                    {/* home line 1 */}
                    <div style={{display:'grid',alignItems:'center',padding:'5px'}}>
                        <div>
                            <span className='home-team-player-icon'>
                                {lineUpsData.lineups.homeStarters[0].number}
                                {(lineUpsData.lineups.homeStarters[0].minOut!=="" || lineUpsData.lineups.homeStarters[0].sub) && <><svg className='groundsubout' viewBox="0 0 14 14" fill="red" name="FootballSubOut"><path fill="#df2357" d="M14 7A7 7 0 110 7a7 7 0 0114 0z"></path><path fill="#111111" d="M9 3.5V7h1.44a.25.25 0 01.19.42l-3.26 3.66a.5.5 0 01-.74 0L3.37 7.42A.25.25 0 013.56 7H5V3.5c0-.28.22-.5.5-.5h3c.28 0 .5.22.5.5z"></path></svg><span className='subouttime'>{lineUpsData.lineups.homeStarters[0].sub.subTime || lineUpsData.lineups.homeStarters[0].minOut}</span></>}
                                {lineUpsData.lineups.homeStarters[0].card && <svg className='yellowcard' xmlns="http://www.w3.org/2000/svg" width="11" height="14" name="FootballYellowCard"><rect width="10" height="14" x="2" rx="2" transform="translate(-1.68)" fill={lineUpsData.lineups.homeStarters[0].card==="FootballYellowCard"?"#FFCE00":lineUpsData.lineups.homeStarters[0].card==="FootballRedCard"?"#DF2357":""} fill-rule="evenodd"></rect></svg>}
                                {lineUpsData.lineups.homeStarters[0].goal && <svg className='footballgoal' xmlns="http://www.w3.org/2000/svg" width="15" height="14" name="FootballGoal"><g transform="translate(.63)" fill="none" fill-rule="evenodd"><circle cx="7" cy="7" r="6" fill="#FDFDFD"></circle><path fill="#222" d="M7 0h.28H7a6.8 6.8 0 01.56.02l.14.01.25.03h.02l.04.01.22.04.13.02.14.03.13.03.24.06.08.03.23.07.04.01.53.2.03.01c.95.42 1.8 1.03 2.47 1.8a5.96 5.96 0 01.62.82l.08.13.06.1c.42.7.72 1.49.87 2.32l.02.07a7.47 7.47 0 01.07 1.85 6.97 6.97 0 01-.94 2.9l-.03.05-.08.13.1-.18-.2.34-.03.04-.37.5-.04.03c-.12.16-.26.3-.4.45l-.05.05-.12.12-.4.35-.07.05-.19.15-.04.02v.01l-.25.17.25-.18a7.3 7.3 0 01-1.67.9l-.05.02-.22.08-.06.02a5.9 5.9 0 01-.37.1l-.16.04a6.95 6.95 0 01-3.11.01l-.06-.01-.15-.04-.09-.02-.03-.01a6.16 6.16 0 01-.24-.07l-.09-.03-.2-.07-.06-.02a7.96 7.96 0 01-.24-.1h-.03c-.5-.22-.96-.48-1.38-.79h-.01l-.04-.03-.2-.16.24.18a6.66 6.66 0 01-.82-.7l-.05-.04a6.47 6.47 0 01-.4-.45l-.04-.04A6.97 6.97 0 01.03 7.66a7.5 7.5 0 010-1.34l.02-.13.01-.11.04-.27.02-.11c.16-.82.45-1.59.87-2.28l.06-.1.08-.13A6.94 6.94 0 014.22.58l.04-.02.51-.2.06-.01.23-.08.06-.01.25-.07c.05 0 .09-.02.13-.03l.14-.03.13-.02L6 .07h.06L6.3.03h.14A3.85 3.85 0 017 0zm1.88 1.3L7.44 2.45a.7.7 0 01-.8.05l-.08-.05L5.12 1.3a6 6 0 00-2.96 2.16l.65 1.72a.7.7 0 01-.2.78L2.54 6 1 7.02v.2a5.96 5.96 0 001.14 3.29l1.83-.09a.7.7 0 01.68.43l.03.09.49 1.77a5.94 5.94 0 003.66 0l.49-1.77a.7.7 0 01.62-.51h.09l1.84.08A5.96 5.96 0 0013 7.02l-1.54-1.01a.7.7 0 01-.3-.75l.03-.08.65-1.72A6.01 6.01 0 008.88 1.3zM7.4 4.5l1.84 1.33c.24.18.35.5.25.79l-.7 2.16a.7.7 0 01-.66.48H5.86a.7.7 0 01-.66-.48l-.7-2.16a.7.7 0 01.25-.78L6.59 4.5a.7.7 0 01.82 0z"></path></g></svg>}
                            </span>
                            <p style={{fontSize:'12px',textAlign:'center'}}>{lineUpsData.lineups.homeStarters[0].name}</p>  
                        </div>
                    </div>
                    {/* home line 2 */}
                    <div style={{display:'grid',alignItems:'center',padding:'5px'}}>
                        {lineUpsData.lineups.homeStarters.filter(starter=>starter.fieldPosition.charAt(0)==='2').map((starter,i)=>(
                            <div key={i}>
                                <span className='home-team-player-icon'>
                                    {starter.number}
                                    {(starter.minOut!=="" || starter.sub) && <><svg className='groundsubout' viewBox="0 0 14 14" fill="red" name="FootballSubOut"><path fill="#df2357" d="M14 7A7 7 0 110 7a7 7 0 0114 0z"></path><path fill="#111111" d="M9 3.5V7h1.44a.25.25 0 01.19.42l-3.26 3.66a.5.5 0 01-.74 0L3.37 7.42A.25.25 0 013.56 7H5V3.5c0-.28.22-.5.5-.5h3c.28 0 .5.22.5.5z"></path></svg><span className='subouttime'>{starter.sub.subTime || starter.minOut}</span></>}
                                    {starter.card && <svg className='yellowcard' xmlns="http://www.w3.org/2000/svg" width="11" height="14" name="FootballYellowCard"><rect width="10" height="14" x="2" rx="2" transform="translate(-1.68)" fill={starter.card==="FootballYellowCard"?"#FFCE00":starter.card==="FootballRedCard"?"#DF2357":""} fill-rule="evenodd"></rect></svg>}
                                    {starter.goal && <svg className='footballgoal' xmlns="http://www.w3.org/2000/svg" width="15" height="14" name="FootballGoal"><g transform="translate(.63)" fill="none" fill-rule="evenodd"><circle cx="7" cy="7" r="6" fill="#FDFDFD"></circle><path fill="#222" d="M7 0h.28H7a6.8 6.8 0 01.56.02l.14.01.25.03h.02l.04.01.22.04.13.02.14.03.13.03.24.06.08.03.23.07.04.01.53.2.03.01c.95.42 1.8 1.03 2.47 1.8a5.96 5.96 0 01.62.82l.08.13.06.1c.42.7.72 1.49.87 2.32l.02.07a7.47 7.47 0 01.07 1.85 6.97 6.97 0 01-.94 2.9l-.03.05-.08.13.1-.18-.2.34-.03.04-.37.5-.04.03c-.12.16-.26.3-.4.45l-.05.05-.12.12-.4.35-.07.05-.19.15-.04.02v.01l-.25.17.25-.18a7.3 7.3 0 01-1.67.9l-.05.02-.22.08-.06.02a5.9 5.9 0 01-.37.1l-.16.04a6.95 6.95 0 01-3.11.01l-.06-.01-.15-.04-.09-.02-.03-.01a6.16 6.16 0 01-.24-.07l-.09-.03-.2-.07-.06-.02a7.96 7.96 0 01-.24-.1h-.03c-.5-.22-.96-.48-1.38-.79h-.01l-.04-.03-.2-.16.24.18a6.66 6.66 0 01-.82-.7l-.05-.04a6.47 6.47 0 01-.4-.45l-.04-.04A6.97 6.97 0 01.03 7.66a7.5 7.5 0 010-1.34l.02-.13.01-.11.04-.27.02-.11c.16-.82.45-1.59.87-2.28l.06-.1.08-.13A6.94 6.94 0 014.22.58l.04-.02.51-.2.06-.01.23-.08.06-.01.25-.07c.05 0 .09-.02.13-.03l.14-.03.13-.02L6 .07h.06L6.3.03h.14A3.85 3.85 0 017 0zm1.88 1.3L7.44 2.45a.7.7 0 01-.8.05l-.08-.05L5.12 1.3a6 6 0 00-2.96 2.16l.65 1.72a.7.7 0 01-.2.78L2.54 6 1 7.02v.2a5.96 5.96 0 001.14 3.29l1.83-.09a.7.7 0 01.68.43l.03.09.49 1.77a5.94 5.94 0 003.66 0l.49-1.77a.7.7 0 01.62-.51h.09l1.84.08A5.96 5.96 0 0013 7.02l-1.54-1.01a.7.7 0 01-.3-.75l.03-.08.65-1.72A6.01 6.01 0 008.88 1.3zM7.4 4.5l1.84 1.33c.24.18.35.5.25.79l-.7 2.16a.7.7 0 01-.66.48H5.86a.7.7 0 01-.66-.48l-.7-2.16a.7.7 0 01.25-.78L6.59 4.5a.7.7 0 01.82 0z"></path></g></svg>}
                                    {starter.goal?.amt >1 && <span className='goalcount'>{starter.goal.amt}</span>}
                                </span>
                                {/* ==="FootballYellowCard" */}
                                <p style={{fontSize:'12px',textAlign:'center'}}>{starter.name}</p>  
                            </div>                        
                        )).reverse()}
                    </div>
                    {/* home line 3 */}
                    <div style={{display:'grid',alignItems:'center',padding:'5px'}}>
                        {lineUpsData.lineups.homeStarters.filter(starter=>starter.fieldPosition.charAt(0)==='3').map((starter,i)=>(
                            <div key={i}>
                                <span className='home-team-player-icon'>
                                    {starter.number}
                                    {(starter.minOut!=="" || starter.sub) && <><svg className='groundsubout' viewBox="0 0 14 14" fill="red" name="FootballSubOut"><path fill="#df2357" d="M14 7A7 7 0 110 7a7 7 0 0114 0z"></path><path fill="#111111" d="M9 3.5V7h1.44a.25.25 0 01.19.42l-3.26 3.66a.5.5 0 01-.74 0L3.37 7.42A.25.25 0 013.56 7H5V3.5c0-.28.22-.5.5-.5h3c.28 0 .5.22.5.5z"></path></svg><span className='subouttime'>{starter.sub.subTime || starter.minOut}</span></>}
                                    {starter.card && <svg className='yellowcard' xmlns="http://www.w3.org/2000/svg" width="11" height="14" name="FootballYellowCard"><rect width="10" height="14" x="2" rx="2" transform="translate(-1.68)" fill={starter.card==="FootballYellowCard"?"#FFCE00":starter.card==="FootballRedCard"?"#DF2357":""} fill-rule="evenodd"></rect></svg>}
                                    {starter.goal && <svg className='footballgoal' xmlns="http://www.w3.org/2000/svg" width="15" height="14" name="FootballGoal"><g transform="translate(.63)" fill="none" fill-rule="evenodd"><circle cx="7" cy="7" r="6" fill="#FDFDFD"></circle><path fill="#222" d="M7 0h.28H7a6.8 6.8 0 01.56.02l.14.01.25.03h.02l.04.01.22.04.13.02.14.03.13.03.24.06.08.03.23.07.04.01.53.2.03.01c.95.42 1.8 1.03 2.47 1.8a5.96 5.96 0 01.62.82l.08.13.06.1c.42.7.72 1.49.87 2.32l.02.07a7.47 7.47 0 01.07 1.85 6.97 6.97 0 01-.94 2.9l-.03.05-.08.13.1-.18-.2.34-.03.04-.37.5-.04.03c-.12.16-.26.3-.4.45l-.05.05-.12.12-.4.35-.07.05-.19.15-.04.02v.01l-.25.17.25-.18a7.3 7.3 0 01-1.67.9l-.05.02-.22.08-.06.02a5.9 5.9 0 01-.37.1l-.16.04a6.95 6.95 0 01-3.11.01l-.06-.01-.15-.04-.09-.02-.03-.01a6.16 6.16 0 01-.24-.07l-.09-.03-.2-.07-.06-.02a7.96 7.96 0 01-.24-.1h-.03c-.5-.22-.96-.48-1.38-.79h-.01l-.04-.03-.2-.16.24.18a6.66 6.66 0 01-.82-.7l-.05-.04a6.47 6.47 0 01-.4-.45l-.04-.04A6.97 6.97 0 01.03 7.66a7.5 7.5 0 010-1.34l.02-.13.01-.11.04-.27.02-.11c.16-.82.45-1.59.87-2.28l.06-.1.08-.13A6.94 6.94 0 014.22.58l.04-.02.51-.2.06-.01.23-.08.06-.01.25-.07c.05 0 .09-.02.13-.03l.14-.03.13-.02L6 .07h.06L6.3.03h.14A3.85 3.85 0 017 0zm1.88 1.3L7.44 2.45a.7.7 0 01-.8.05l-.08-.05L5.12 1.3a6 6 0 00-2.96 2.16l.65 1.72a.7.7 0 01-.2.78L2.54 6 1 7.02v.2a5.96 5.96 0 001.14 3.29l1.83-.09a.7.7 0 01.68.43l.03.09.49 1.77a5.94 5.94 0 003.66 0l.49-1.77a.7.7 0 01.62-.51h.09l1.84.08A5.96 5.96 0 0013 7.02l-1.54-1.01a.7.7 0 01-.3-.75l.03-.08.65-1.72A6.01 6.01 0 008.88 1.3zM7.4 4.5l1.84 1.33c.24.18.35.5.25.79l-.7 2.16a.7.7 0 01-.66.48H5.86a.7.7 0 01-.66-.48l-.7-2.16a.7.7 0 01.25-.78L6.59 4.5a.7.7 0 01.82 0z"></path></g></svg>}
                                    {starter.goal?.amt >1 && <span className='goalcount'>{starter.goal.amt}</span>}
                                </span>
                                <p style={{fontSize:'12px',textAlign:'center'}}>{starter.name}</p>  
                            </div>                        
                        )).reverse()}
                    </div>
                    {/* home line 4 */}
                    <div style={{display:'grid',alignItems:'center',padding:'5px'}}>
                        {lineUpsData.lineups.homeStarters.filter(starter=>starter.fieldPosition.charAt(0)==='4').map((starter,i)=>(
                            <div key={i}>
                                <span className='home-team-player-icon'>
                                    {starter.number}
                                    {(starter.minOut!=="" || starter.sub) && <><svg className='groundsubout' viewBox="0 0 14 14" fill="red" name="FootballSubOut"><path fill="#df2357" d="M14 7A7 7 0 110 7a7 7 0 0114 0z"></path><path fill="#111111" d="M9 3.5V7h1.44a.25.25 0 01.19.42l-3.26 3.66a.5.5 0 01-.74 0L3.37 7.42A.25.25 0 013.56 7H5V3.5c0-.28.22-.5.5-.5h3c.28 0 .5.22.5.5z"></path></svg><span className='subouttime'>{starter.sub.subTime || starter.minOut}</span></>}
                                    {starter.card && <svg className='yellowcard' xmlns="http://www.w3.org/2000/svg" width="11" height="14" name="FootballYellowCard"><rect width="10" height="14" x="2" rx="2" transform="translate(-1.68)" fill={starter.card==="FootballYellowCard"?"#FFCE00":starter.card==="FootballRedCard"?"#DF2357":""} fill-rule="evenodd"></rect></svg>}
                                    {starter.goal && <svg className='footballgoal' xmlns="http://www.w3.org/2000/svg" width="15" height="14" name="FootballGoal"><g transform="translate(.63)" fill="none" fill-rule="evenodd"><circle cx="7" cy="7" r="6" fill="#FDFDFD"></circle><path fill="#222" d="M7 0h.28H7a6.8 6.8 0 01.56.02l.14.01.25.03h.02l.04.01.22.04.13.02.14.03.13.03.24.06.08.03.23.07.04.01.53.2.03.01c.95.42 1.8 1.03 2.47 1.8a5.96 5.96 0 01.62.82l.08.13.06.1c.42.7.72 1.49.87 2.32l.02.07a7.47 7.47 0 01.07 1.85 6.97 6.97 0 01-.94 2.9l-.03.05-.08.13.1-.18-.2.34-.03.04-.37.5-.04.03c-.12.16-.26.3-.4.45l-.05.05-.12.12-.4.35-.07.05-.19.15-.04.02v.01l-.25.17.25-.18a7.3 7.3 0 01-1.67.9l-.05.02-.22.08-.06.02a5.9 5.9 0 01-.37.1l-.16.04a6.95 6.95 0 01-3.11.01l-.06-.01-.15-.04-.09-.02-.03-.01a6.16 6.16 0 01-.24-.07l-.09-.03-.2-.07-.06-.02a7.96 7.96 0 01-.24-.1h-.03c-.5-.22-.96-.48-1.38-.79h-.01l-.04-.03-.2-.16.24.18a6.66 6.66 0 01-.82-.7l-.05-.04a6.47 6.47 0 01-.4-.45l-.04-.04A6.97 6.97 0 01.03 7.66a7.5 7.5 0 010-1.34l.02-.13.01-.11.04-.27.02-.11c.16-.82.45-1.59.87-2.28l.06-.1.08-.13A6.94 6.94 0 014.22.58l.04-.02.51-.2.06-.01.23-.08.06-.01.25-.07c.05 0 .09-.02.13-.03l.14-.03.13-.02L6 .07h.06L6.3.03h.14A3.85 3.85 0 017 0zm1.88 1.3L7.44 2.45a.7.7 0 01-.8.05l-.08-.05L5.12 1.3a6 6 0 00-2.96 2.16l.65 1.72a.7.7 0 01-.2.78L2.54 6 1 7.02v.2a5.96 5.96 0 001.14 3.29l1.83-.09a.7.7 0 01.68.43l.03.09.49 1.77a5.94 5.94 0 003.66 0l.49-1.77a.7.7 0 01.62-.51h.09l1.84.08A5.96 5.96 0 0013 7.02l-1.54-1.01a.7.7 0 01-.3-.75l.03-.08.65-1.72A6.01 6.01 0 008.88 1.3zM7.4 4.5l1.84 1.33c.24.18.35.5.25.79l-.7 2.16a.7.7 0 01-.66.48H5.86a.7.7 0 01-.66-.48l-.7-2.16a.7.7 0 01.25-.78L6.59 4.5a.7.7 0 01.82 0z"></path></g></svg>}
                                    {starter.goal?.amt >1 && <span className='goalcount'>{starter.goal.amt}</span>}
                                </span>
                                <p style={{fontSize:'12px',textAlign:'center'}}>{starter.name}</p>  
                            </div>                        
                        )).reverse()}
                    </div>
                    {/* home line 5 */}
                    <div style={{display:'grid',alignItems:'center',padding:'5px'}}>
                        {lineUpsData.lineups.homeStarters.filter(starter=>starter.fieldPosition.charAt(0)==='5').map((starter,i)=>(
                            <div key={i}>
                                <span className='home-team-player-icon'>
                                    {starter.number}
                                    {(starter.minOut!=="" || starter.sub) && <><svg className='groundsubout' viewBox="0 0 14 14" fill="red" name="FootballSubOut"><path fill="#df2357" d="M14 7A7 7 0 110 7a7 7 0 0114 0z"></path><path fill="#111111" d="M9 3.5V7h1.44a.25.25 0 01.19.42l-3.26 3.66a.5.5 0 01-.74 0L3.37 7.42A.25.25 0 013.56 7H5V3.5c0-.28.22-.5.5-.5h3c.28 0 .5.22.5.5z"></path></svg><span className='subouttime'>{starter.sub.subTime || starter.minOut}</span></>}
                                    {starter.card && <svg className='yellowcard' xmlns="http://www.w3.org/2000/svg" width="11" height="14" name="FootballYellowCard"><rect width="10" height="14" x="2" rx="2" transform="translate(-1.68)" fill={starter.card==="FootballYellowCard"?"#FFCE00":starter.card==="FootballRedCard"?"#DF2357":""} fill-rule="evenodd"></rect></svg>}
                                    {starter.goal && <svg className='footballgoal' xmlns="http://www.w3.org/2000/svg" width="15" height="14" name="FootballGoal"><g transform="translate(.63)" fill="none" fill-rule="evenodd"><circle cx="7" cy="7" r="6" fill="#FDFDFD"></circle><path fill="#222" d="M7 0h.28H7a6.8 6.8 0 01.56.02l.14.01.25.03h.02l.04.01.22.04.13.02.14.03.13.03.24.06.08.03.23.07.04.01.53.2.03.01c.95.42 1.8 1.03 2.47 1.8a5.96 5.96 0 01.62.82l.08.13.06.1c.42.7.72 1.49.87 2.32l.02.07a7.47 7.47 0 01.07 1.85 6.97 6.97 0 01-.94 2.9l-.03.05-.08.13.1-.18-.2.34-.03.04-.37.5-.04.03c-.12.16-.26.3-.4.45l-.05.05-.12.12-.4.35-.07.05-.19.15-.04.02v.01l-.25.17.25-.18a7.3 7.3 0 01-1.67.9l-.05.02-.22.08-.06.02a5.9 5.9 0 01-.37.1l-.16.04a6.95 6.95 0 01-3.11.01l-.06-.01-.15-.04-.09-.02-.03-.01a6.16 6.16 0 01-.24-.07l-.09-.03-.2-.07-.06-.02a7.96 7.96 0 01-.24-.1h-.03c-.5-.22-.96-.48-1.38-.79h-.01l-.04-.03-.2-.16.24.18a6.66 6.66 0 01-.82-.7l-.05-.04a6.47 6.47 0 01-.4-.45l-.04-.04A6.97 6.97 0 01.03 7.66a7.5 7.5 0 010-1.34l.02-.13.01-.11.04-.27.02-.11c.16-.82.45-1.59.87-2.28l.06-.1.08-.13A6.94 6.94 0 014.22.58l.04-.02.51-.2.06-.01.23-.08.06-.01.25-.07c.05 0 .09-.02.13-.03l.14-.03.13-.02L6 .07h.06L6.3.03h.14A3.85 3.85 0 017 0zm1.88 1.3L7.44 2.45a.7.7 0 01-.8.05l-.08-.05L5.12 1.3a6 6 0 00-2.96 2.16l.65 1.72a.7.7 0 01-.2.78L2.54 6 1 7.02v.2a5.96 5.96 0 001.14 3.29l1.83-.09a.7.7 0 01.68.43l.03.09.49 1.77a5.94 5.94 0 003.66 0l.49-1.77a.7.7 0 01.62-.51h.09l1.84.08A5.96 5.96 0 0013 7.02l-1.54-1.01a.7.7 0 01-.3-.75l.03-.08.65-1.72A6.01 6.01 0 008.88 1.3zM7.4 4.5l1.84 1.33c.24.18.35.5.25.79l-.7 2.16a.7.7 0 01-.66.48H5.86a.7.7 0 01-.66-.48l-.7-2.16a.7.7 0 01.25-.78L6.59 4.5a.7.7 0 01.82 0z"></path></g></svg>}
                                    {starter.goal?.amt >1 && <span className='goalcount'>{starter.goal.amt}</span>}
                                </span>
                                <p style={{fontSize:'12px',textAlign:'center'}}>{starter.name}</p>  
                            </div>                        
                        )).reverse()}    
                    </div>
                </div>
                {/* away team */}
                <div style={{height:'100%',width:'50%',display:'flex'}}>
                    {/* away line 5 */}
                    <div style={{display:'grid',alignItems:'center',padding:'5px'}}>
                        {lineUpsData.lineups.awayStarters.filter(starter=>starter.fieldPosition.charAt(0)==='5').map((starter,i)=>(
                            <div key={i}>
                                <span className='away-team-player-icon'>
                                    {starter.number}
                                    {(starter.minOut!=="" || starter.sub) && <><svg className='groundsubout' viewBox="0 0 14 14" fill="red" name="FootballSubOut"><path fill="#df2357" d="M14 7A7 7 0 110 7a7 7 0 0114 0z"></path><path fill="#111111" d="M9 3.5V7h1.44a.25.25 0 01.19.42l-3.26 3.66a.5.5 0 01-.74 0L3.37 7.42A.25.25 0 013.56 7H5V3.5c0-.28.22-.5.5-.5h3c.28 0 .5.22.5.5z"></path></svg><span className='subouttime'>{starter.sub.subTime || starter.minOut}</span></>}
                                    {starter.card && <svg className='yellowcard' xmlns="http://www.w3.org/2000/svg" width="11" height="14" name="FootballYellowCard"><rect width="10" height="14" x="2" rx="2" transform="translate(-1.68)" fill={starter.card==="FootballYellowCard"?"#FFCE00":starter.card==="FootballRedCard"?"#DF2357":""} fill-rule="evenodd"></rect></svg>}
                                    {starter.goal && <svg className='footballgoal' xmlns="http://www.w3.org/2000/svg" width="15" height="14" name="FootballGoal"><g transform="translate(.63)" fill="none" fill-rule="evenodd"><circle cx="7" cy="7" r="6" fill="#FDFDFD"></circle><path fill="#222" d="M7 0h.28H7a6.8 6.8 0 01.56.02l.14.01.25.03h.02l.04.01.22.04.13.02.14.03.13.03.24.06.08.03.23.07.04.01.53.2.03.01c.95.42 1.8 1.03 2.47 1.8a5.96 5.96 0 01.62.82l.08.13.06.1c.42.7.72 1.49.87 2.32l.02.07a7.47 7.47 0 01.07 1.85 6.97 6.97 0 01-.94 2.9l-.03.05-.08.13.1-.18-.2.34-.03.04-.37.5-.04.03c-.12.16-.26.3-.4.45l-.05.05-.12.12-.4.35-.07.05-.19.15-.04.02v.01l-.25.17.25-.18a7.3 7.3 0 01-1.67.9l-.05.02-.22.08-.06.02a5.9 5.9 0 01-.37.1l-.16.04a6.95 6.95 0 01-3.11.01l-.06-.01-.15-.04-.09-.02-.03-.01a6.16 6.16 0 01-.24-.07l-.09-.03-.2-.07-.06-.02a7.96 7.96 0 01-.24-.1h-.03c-.5-.22-.96-.48-1.38-.79h-.01l-.04-.03-.2-.16.24.18a6.66 6.66 0 01-.82-.7l-.05-.04a6.47 6.47 0 01-.4-.45l-.04-.04A6.97 6.97 0 01.03 7.66a7.5 7.5 0 010-1.34l.02-.13.01-.11.04-.27.02-.11c.16-.82.45-1.59.87-2.28l.06-.1.08-.13A6.94 6.94 0 014.22.58l.04-.02.51-.2.06-.01.23-.08.06-.01.25-.07c.05 0 .09-.02.13-.03l.14-.03.13-.02L6 .07h.06L6.3.03h.14A3.85 3.85 0 017 0zm1.88 1.3L7.44 2.45a.7.7 0 01-.8.05l-.08-.05L5.12 1.3a6 6 0 00-2.96 2.16l.65 1.72a.7.7 0 01-.2.78L2.54 6 1 7.02v.2a5.96 5.96 0 001.14 3.29l1.83-.09a.7.7 0 01.68.43l.03.09.49 1.77a5.94 5.94 0 003.66 0l.49-1.77a.7.7 0 01.62-.51h.09l1.84.08A5.96 5.96 0 0013 7.02l-1.54-1.01a.7.7 0 01-.3-.75l.03-.08.65-1.72A6.01 6.01 0 008.88 1.3zM7.4 4.5l1.84 1.33c.24.18.35.5.25.79l-.7 2.16a.7.7 0 01-.66.48H5.86a.7.7 0 01-.66-.48l-.7-2.16a.7.7 0 01.25-.78L6.59 4.5a.7.7 0 01.82 0z"></path></g></svg>}
                                    {starter.goal?.amt >1 && <span className='goalcount'>{starter.goal.amt}</span>}
                                </span>
                                <p style={{fontSize:'12px',textAlign:'center'}}>{starter.name}</p>  
                            </div>                        
                        ))}
                    </div>
                    {/* away line 4 */}
                    <div style={{display:'grid',alignItems:'center',padding:'5px'}}>
                        {lineUpsData.lineups.awayStarters.filter(starter=>starter.fieldPosition.charAt(0)==='4').map((starter,i)=>(
                            <div key={i}>
                                <span className='away-team-player-icon'>
                                    {starter.number}
                                    {(starter.minOut!=="" || starter.sub) && <><svg className='groundsubout' viewBox="0 0 14 14" fill="red" name="FootballSubOut"><path fill="#df2357" d="M14 7A7 7 0 110 7a7 7 0 0114 0z"></path><path fill="#111111" d="M9 3.5V7h1.44a.25.25 0 01.19.42l-3.26 3.66a.5.5 0 01-.74 0L3.37 7.42A.25.25 0 013.56 7H5V3.5c0-.28.22-.5.5-.5h3c.28 0 .5.22.5.5z"></path></svg><span className='subouttime'>{starter.sub.subTime || starter.minOut}</span></>}
                                    {starter.card && <svg className='yellowcard' xmlns="http://www.w3.org/2000/svg" width="11" height="14" name="FootballYellowCard"><rect width="10" height="14" x="2" rx="2" transform="translate(-1.68)" fill={starter.card==="FootballYellowCard"?"#FFCE00":starter.card==="FootballRedCard"?"#DF2357":""} fill-rule="evenodd"></rect></svg>}
                                    {starter.goal && <svg className='footballgoal' xmlns="http://www.w3.org/2000/svg" width="15" height="14" name="FootballGoal"><g transform="translate(.63)" fill="none" fill-rule="evenodd"><circle cx="7" cy="7" r="6" fill="#FDFDFD"></circle><path fill="#222" d="M7 0h.28H7a6.8 6.8 0 01.56.02l.14.01.25.03h.02l.04.01.22.04.13.02.14.03.13.03.24.06.08.03.23.07.04.01.53.2.03.01c.95.42 1.8 1.03 2.47 1.8a5.96 5.96 0 01.62.82l.08.13.06.1c.42.7.72 1.49.87 2.32l.02.07a7.47 7.47 0 01.07 1.85 6.97 6.97 0 01-.94 2.9l-.03.05-.08.13.1-.18-.2.34-.03.04-.37.5-.04.03c-.12.16-.26.3-.4.45l-.05.05-.12.12-.4.35-.07.05-.19.15-.04.02v.01l-.25.17.25-.18a7.3 7.3 0 01-1.67.9l-.05.02-.22.08-.06.02a5.9 5.9 0 01-.37.1l-.16.04a6.95 6.95 0 01-3.11.01l-.06-.01-.15-.04-.09-.02-.03-.01a6.16 6.16 0 01-.24-.07l-.09-.03-.2-.07-.06-.02a7.96 7.96 0 01-.24-.1h-.03c-.5-.22-.96-.48-1.38-.79h-.01l-.04-.03-.2-.16.24.18a6.66 6.66 0 01-.82-.7l-.05-.04a6.47 6.47 0 01-.4-.45l-.04-.04A6.97 6.97 0 01.03 7.66a7.5 7.5 0 010-1.34l.02-.13.01-.11.04-.27.02-.11c.16-.82.45-1.59.87-2.28l.06-.1.08-.13A6.94 6.94 0 014.22.58l.04-.02.51-.2.06-.01.23-.08.06-.01.25-.07c.05 0 .09-.02.13-.03l.14-.03.13-.02L6 .07h.06L6.3.03h.14A3.85 3.85 0 017 0zm1.88 1.3L7.44 2.45a.7.7 0 01-.8.05l-.08-.05L5.12 1.3a6 6 0 00-2.96 2.16l.65 1.72a.7.7 0 01-.2.78L2.54 6 1 7.02v.2a5.96 5.96 0 001.14 3.29l1.83-.09a.7.7 0 01.68.43l.03.09.49 1.77a5.94 5.94 0 003.66 0l.49-1.77a.7.7 0 01.62-.51h.09l1.84.08A5.96 5.96 0 0013 7.02l-1.54-1.01a.7.7 0 01-.3-.75l.03-.08.65-1.72A6.01 6.01 0 008.88 1.3zM7.4 4.5l1.84 1.33c.24.18.35.5.25.79l-.7 2.16a.7.7 0 01-.66.48H5.86a.7.7 0 01-.66-.48l-.7-2.16a.7.7 0 01.25-.78L6.59 4.5a.7.7 0 01.82 0z"></path></g></svg>}
                                    {starter.goal?.amt >1 && <span className='goalcount'>{starter.goal.amt}</span>}
                                </span>
                                <p style={{fontSize:'12px',textAlign:'center'}}>{starter.name}</p>  
                            </div>                        
                        ))}
                    </div>
                    {/* away line 3 */}
                    <div style={{display:'grid',alignItems:'center',padding:'5px'}}>
                        {lineUpsData.lineups.awayStarters.filter(starter=>starter.fieldPosition.charAt(0)==='3').map((starter,i)=>(
                            <div key={i}>
                                <span className='away-team-player-icon'>
                                    {starter.number}
                                    {(starter.minOut!=="" || starter.sub) && <><svg className='groundsubout' viewBox="0 0 14 14" fill="red" name="FootballSubOut"><path fill="#df2357" d="M14 7A7 7 0 110 7a7 7 0 0114 0z"></path><path fill="#111111" d="M9 3.5V7h1.44a.25.25 0 01.19.42l-3.26 3.66a.5.5 0 01-.74 0L3.37 7.42A.25.25 0 013.56 7H5V3.5c0-.28.22-.5.5-.5h3c.28 0 .5.22.5.5z"></path></svg><span className='subouttime'>{starter.sub.subTime || starter.minOut}</span></>}
                                    {starter.card && <svg className='yellowcard' xmlns="http://www.w3.org/2000/svg" width="11" height="14" name="FootballYellowCard"><rect width="10" height="14" x="2" rx="2" transform="translate(-1.68)" fill={starter.card==="FootballYellowCard"?"#FFCE00":starter.card==="FootballRedCard"?"#DF2357":""} fill-rule="evenodd"></rect></svg>}
                                    {starter.goal && <svg className='footballgoal' xmlns="http://www.w3.org/2000/svg" width="15" height="14" name="FootballGoal"><g transform="translate(.63)" fill="none" fill-rule="evenodd"><circle cx="7" cy="7" r="6" fill="#FDFDFD"></circle><path fill="#222" d="M7 0h.28H7a6.8 6.8 0 01.56.02l.14.01.25.03h.02l.04.01.22.04.13.02.14.03.13.03.24.06.08.03.23.07.04.01.53.2.03.01c.95.42 1.8 1.03 2.47 1.8a5.96 5.96 0 01.62.82l.08.13.06.1c.42.7.72 1.49.87 2.32l.02.07a7.47 7.47 0 01.07 1.85 6.97 6.97 0 01-.94 2.9l-.03.05-.08.13.1-.18-.2.34-.03.04-.37.5-.04.03c-.12.16-.26.3-.4.45l-.05.05-.12.12-.4.35-.07.05-.19.15-.04.02v.01l-.25.17.25-.18a7.3 7.3 0 01-1.67.9l-.05.02-.22.08-.06.02a5.9 5.9 0 01-.37.1l-.16.04a6.95 6.95 0 01-3.11.01l-.06-.01-.15-.04-.09-.02-.03-.01a6.16 6.16 0 01-.24-.07l-.09-.03-.2-.07-.06-.02a7.96 7.96 0 01-.24-.1h-.03c-.5-.22-.96-.48-1.38-.79h-.01l-.04-.03-.2-.16.24.18a6.66 6.66 0 01-.82-.7l-.05-.04a6.47 6.47 0 01-.4-.45l-.04-.04A6.97 6.97 0 01.03 7.66a7.5 7.5 0 010-1.34l.02-.13.01-.11.04-.27.02-.11c.16-.82.45-1.59.87-2.28l.06-.1.08-.13A6.94 6.94 0 014.22.58l.04-.02.51-.2.06-.01.23-.08.06-.01.25-.07c.05 0 .09-.02.13-.03l.14-.03.13-.02L6 .07h.06L6.3.03h.14A3.85 3.85 0 017 0zm1.88 1.3L7.44 2.45a.7.7 0 01-.8.05l-.08-.05L5.12 1.3a6 6 0 00-2.96 2.16l.65 1.72a.7.7 0 01-.2.78L2.54 6 1 7.02v.2a5.96 5.96 0 001.14 3.29l1.83-.09a.7.7 0 01.68.43l.03.09.49 1.77a5.94 5.94 0 003.66 0l.49-1.77a.7.7 0 01.62-.51h.09l1.84.08A5.96 5.96 0 0013 7.02l-1.54-1.01a.7.7 0 01-.3-.75l.03-.08.65-1.72A6.01 6.01 0 008.88 1.3zM7.4 4.5l1.84 1.33c.24.18.35.5.25.79l-.7 2.16a.7.7 0 01-.66.48H5.86a.7.7 0 01-.66-.48l-.7-2.16a.7.7 0 01.25-.78L6.59 4.5a.7.7 0 01.82 0z"></path></g></svg>}
                                    {starter.goal?.amt >1 && <span className='goalcount'>{starter.goal.amt}</span>}
                                </span>
                                <p style={{fontSize:'12px',textAlign:'center'}}>{starter.name}</p>  
                            </div>                        
                        ))}
                    </div>
                    {/* away line 2 */}
                    <div style={{display:'grid',alignItems:'center',padding:'5px'}}>
                        {lineUpsData.lineups.awayStarters.filter(starter=>starter.fieldPosition.charAt(0)==='2').map((starter,i)=>(
                            <div key={i}>
                                <span className='away-team-player-icon'>
                                    {starter.number}
                                    {(starter.minOut!=="" || starter.sub) && <><svg className='groundsubout' viewBox="0 0 14 14" fill="red" name="FootballSubOut"><path fill="#df2357" d="M14 7A7 7 0 110 7a7 7 0 0114 0z"></path><path fill="#111111" d="M9 3.5V7h1.44a.25.25 0 01.19.42l-3.26 3.66a.5.5 0 01-.74 0L3.37 7.42A.25.25 0 013.56 7H5V3.5c0-.28.22-.5.5-.5h3c.28 0 .5.22.5.5z"></path></svg><span className='subouttime'>{starter.sub.subTime || starter.minOut}</span></>}
                                    {starter.card && <svg className='yellowcard' xmlns="http://www.w3.org/2000/svg" width="11" height="14" name="FootballYellowCard"><rect width="10" height="14" x="2" rx="2" transform="translate(-1.68)" fill={starter.card==="FootballYellowCard"?"#FFCE00":starter.card==="FootballRedCard"?"#DF2357":""} fill-rule="evenodd"></rect></svg>}
                                    {starter.goal && <svg className='footballgoal' xmlns="http://www.w3.org/2000/svg" width="15" height="14" name="FootballGoal"><g transform="translate(.63)" fill="none" fill-rule="evenodd"><circle cx="7" cy="7" r="6" fill="#FDFDFD"></circle><path fill="#222" d="M7 0h.28H7a6.8 6.8 0 01.56.02l.14.01.25.03h.02l.04.01.22.04.13.02.14.03.13.03.24.06.08.03.23.07.04.01.53.2.03.01c.95.42 1.8 1.03 2.47 1.8a5.96 5.96 0 01.62.82l.08.13.06.1c.42.7.72 1.49.87 2.32l.02.07a7.47 7.47 0 01.07 1.85 6.97 6.97 0 01-.94 2.9l-.03.05-.08.13.1-.18-.2.34-.03.04-.37.5-.04.03c-.12.16-.26.3-.4.45l-.05.05-.12.12-.4.35-.07.05-.19.15-.04.02v.01l-.25.17.25-.18a7.3 7.3 0 01-1.67.9l-.05.02-.22.08-.06.02a5.9 5.9 0 01-.37.1l-.16.04a6.95 6.95 0 01-3.11.01l-.06-.01-.15-.04-.09-.02-.03-.01a6.16 6.16 0 01-.24-.07l-.09-.03-.2-.07-.06-.02a7.96 7.96 0 01-.24-.1h-.03c-.5-.22-.96-.48-1.38-.79h-.01l-.04-.03-.2-.16.24.18a6.66 6.66 0 01-.82-.7l-.05-.04a6.47 6.47 0 01-.4-.45l-.04-.04A6.97 6.97 0 01.03 7.66a7.5 7.5 0 010-1.34l.02-.13.01-.11.04-.27.02-.11c.16-.82.45-1.59.87-2.28l.06-.1.08-.13A6.94 6.94 0 014.22.58l.04-.02.51-.2.06-.01.23-.08.06-.01.25-.07c.05 0 .09-.02.13-.03l.14-.03.13-.02L6 .07h.06L6.3.03h.14A3.85 3.85 0 017 0zm1.88 1.3L7.44 2.45a.7.7 0 01-.8.05l-.08-.05L5.12 1.3a6 6 0 00-2.96 2.16l.65 1.72a.7.7 0 01-.2.78L2.54 6 1 7.02v.2a5.96 5.96 0 001.14 3.29l1.83-.09a.7.7 0 01.68.43l.03.09.49 1.77a5.94 5.94 0 003.66 0l.49-1.77a.7.7 0 01.62-.51h.09l1.84.08A5.96 5.96 0 0013 7.02l-1.54-1.01a.7.7 0 01-.3-.75l.03-.08.65-1.72A6.01 6.01 0 008.88 1.3zM7.4 4.5l1.84 1.33c.24.18.35.5.25.79l-.7 2.16a.7.7 0 01-.66.48H5.86a.7.7 0 01-.66-.48l-.7-2.16a.7.7 0 01.25-.78L6.59 4.5a.7.7 0 01.82 0z"></path></g></svg>}
                                    {starter.goal?.amt >1 && <span className='goalcount'>{starter.goal.amt}</span>}
                                </span>
                                <p style={{fontSize:'12px',textAlign:'center'}}>{starter.name}</p>  
                            </div>                        
                        ))}
                    </div>
                    {/* away line 1 */}
                    <div style={{display:'grid',alignItems:'center',padding:'5px'}}>
                        <div>
                            <span className='away-team-player-icon'>
                                {lineUpsData.lineups.awayStarters[0].number}
                                {(lineUpsData.lineups.awayStarters[0].minOut!=="" || lineUpsData.lineups.awayStarters[0].sub) && <><svg className='groundsubout' viewBox="0 0 14 14" fill="red" name="FootballSubOut"><path fill="#df2357" d="M14 7A7 7 0 110 7a7 7 0 0114 0z"></path><path fill="#111111" d="M9 3.5V7h1.44a.25.25 0 01.19.42l-3.26 3.66a.5.5 0 01-.74 0L3.37 7.42A.25.25 0 013.56 7H5V3.5c0-.28.22-.5.5-.5h3c.28 0 .5.22.5.5z"></path></svg><span className='subouttime'>{lineUpsData.lineups.awayStarters[0].sub.subTime || lineUpsData.lineups.awayStarters[0].minOut}</span></>}
                                {lineUpsData.lineups.awayStarters[0].card && <svg className='yellowcard' xmlns="http://www.w3.org/2000/svg" width="11" height="14" name="FootballYellowCard"><rect width="10" height="14" x="2" rx="2" transform="translate(-1.68)" fill={lineUpsData.lineups.awayStarters[0].card==="FootballYellowCard"?"#FFCE00":lineUpsData.lineups.awayStarters[0].card==="FootballRedCard"?"#DF2357":""} fill-rule="evenodd"></rect></svg>}
                                {lineUpsData.lineups.awayStarters[0].goal && <svg className='footballgoal' xmlns="http://www.w3.org/2000/svg" width="15" height="14" name="FootballGoal"><g transform="translate(.63)" fill="none" fill-rule="evenodd"><circle cx="7" cy="7" r="6" fill="#FDFDFD"></circle><path fill="#222" d="M7 0h.28H7a6.8 6.8 0 01.56.02l.14.01.25.03h.02l.04.01.22.04.13.02.14.03.13.03.24.06.08.03.23.07.04.01.53.2.03.01c.95.42 1.8 1.03 2.47 1.8a5.96 5.96 0 01.62.82l.08.13.06.1c.42.7.72 1.49.87 2.32l.02.07a7.47 7.47 0 01.07 1.85 6.97 6.97 0 01-.94 2.9l-.03.05-.08.13.1-.18-.2.34-.03.04-.37.5-.04.03c-.12.16-.26.3-.4.45l-.05.05-.12.12-.4.35-.07.05-.19.15-.04.02v.01l-.25.17.25-.18a7.3 7.3 0 01-1.67.9l-.05.02-.22.08-.06.02a5.9 5.9 0 01-.37.1l-.16.04a6.95 6.95 0 01-3.11.01l-.06-.01-.15-.04-.09-.02-.03-.01a6.16 6.16 0 01-.24-.07l-.09-.03-.2-.07-.06-.02a7.96 7.96 0 01-.24-.1h-.03c-.5-.22-.96-.48-1.38-.79h-.01l-.04-.03-.2-.16.24.18a6.66 6.66 0 01-.82-.7l-.05-.04a6.47 6.47 0 01-.4-.45l-.04-.04A6.97 6.97 0 01.03 7.66a7.5 7.5 0 010-1.34l.02-.13.01-.11.04-.27.02-.11c.16-.82.45-1.59.87-2.28l.06-.1.08-.13A6.94 6.94 0 014.22.58l.04-.02.51-.2.06-.01.23-.08.06-.01.25-.07c.05 0 .09-.02.13-.03l.14-.03.13-.02L6 .07h.06L6.3.03h.14A3.85 3.85 0 017 0zm1.88 1.3L7.44 2.45a.7.7 0 01-.8.05l-.08-.05L5.12 1.3a6 6 0 00-2.96 2.16l.65 1.72a.7.7 0 01-.2.78L2.54 6 1 7.02v.2a5.96 5.96 0 001.14 3.29l1.83-.09a.7.7 0 01.68.43l.03.09.49 1.77a5.94 5.94 0 003.66 0l.49-1.77a.7.7 0 01.62-.51h.09l1.84.08A5.96 5.96 0 0013 7.02l-1.54-1.01a.7.7 0 01-.3-.75l.03-.08.65-1.72A6.01 6.01 0 008.88 1.3zM7.4 4.5l1.84 1.33c.24.18.35.5.25.79l-.7 2.16a.7.7 0 01-.66.48H5.86a.7.7 0 01-.66-.48l-.7-2.16a.7.7 0 01.25-.78L6.59 4.5a.7.7 0 01.82 0z"></path></g></svg>}
                            </span>
                            <p style={{fontSize:'12px',textAlign:'center'}}>{lineUpsData.lineups.awayStarters[0].name}</p>  
                        </div>   
                    </div>
                </div>      
            </div>}
            {lineUpsData.fieldData && <span style={{marginLeft:'auto',marginRight:'31px'}}>{lineUpsData.fieldData.awayTeamName+' '+lineUpsData.fieldData.awayFormation}</span>}
        </div>
        <div style={{marginTop:'5px',marginBottom:'5px'}}>Substitutions</div>
        <div className='substitutions'>
            {Array.from({ length: Math.max(lineUpsData.lineups.homeSubs.length,lineUpsData.lineups.awaySubs.length) }, (_, index) =>(
                <div key={index}>
                    {index!==0 && <hr/>}
                    <div style={{display:'flex',width:'100%'}}>
                        <div style={{width:'50%',display:'flex'}}>
                            {lineUpsData.lineups.homeSubs[index] && <><span class="subplayericon">
                                {lineUpsData.lineups.homeSubs[index]?.number}
                                {lineUpsData.lineups.homeSubs[index]?.sub && <svg className='subinicon' viewBox="0 0 14 14" fill="red" name="FootballSubIn"><path fill="#23df8c" d="M14 7A7 7 0 100 7a7 7 0 0014 0z"></path><path fill="#111111" d="M9 10.5V7h1.44a.25.25 0 00.19-.42L7.37 2.92a.5.5 0 00-.74 0L3.37 6.58a.25.25 0 00.19.42H5v3.5c0 .28.22.5.5.5h3a.5.5 0 00.5-.5z"></path></svg>}
                                <span className='subintime'>{lineUpsData.lineups.homeSubs[index]?.sub?.subTime}</span>
                                {lineUpsData.lineups.homeSubs[index]?.card && <svg className='yellowcard' xmlns="http://www.w3.org/2000/svg" width="11" height="14" name="FootballYellowCard"><rect width="10" height="14" x="2" rx="2" transform="translate(-1.68)" fill={lineUpsData.lineups.homeSubs[index].card==="FootballYellowCard"?"#FFCE00":lineUpsData.lineups.homeSubs[index].card==="FootballRedCard"?"#DF2357":""} fill-rule="evenodd"></rect></svg>}
                                {lineUpsData.lineups.homeSubs[index]?.goal && <svg className='footballgoal' xmlns="http://www.w3.org/2000/svg" width="15" height="14" name="FootballGoal"><g transform="translate(.63)" fill="none" fill-rule="evenodd"><circle cx="7" cy="7" r="6" fill="#FDFDFD"></circle><path fill="#222" d="M7 0h.28H7a6.8 6.8 0 01.56.02l.14.01.25.03h.02l.04.01.22.04.13.02.14.03.13.03.24.06.08.03.23.07.04.01.53.2.03.01c.95.42 1.8 1.03 2.47 1.8a5.96 5.96 0 01.62.82l.08.13.06.1c.42.7.72 1.49.87 2.32l.02.07a7.47 7.47 0 01.07 1.85 6.97 6.97 0 01-.94 2.9l-.03.05-.08.13.1-.18-.2.34-.03.04-.37.5-.04.03c-.12.16-.26.3-.4.45l-.05.05-.12.12-.4.35-.07.05-.19.15-.04.02v.01l-.25.17.25-.18a7.3 7.3 0 01-1.67.9l-.05.02-.22.08-.06.02a5.9 5.9 0 01-.37.1l-.16.04a6.95 6.95 0 01-3.11.01l-.06-.01-.15-.04-.09-.02-.03-.01a6.16 6.16 0 01-.24-.07l-.09-.03-.2-.07-.06-.02a7.96 7.96 0 01-.24-.1h-.03c-.5-.22-.96-.48-1.38-.79h-.01l-.04-.03-.2-.16.24.18a6.66 6.66 0 01-.82-.7l-.05-.04a6.47 6.47 0 01-.4-.45l-.04-.04A6.97 6.97 0 01.03 7.66a7.5 7.5 0 010-1.34l.02-.13.01-.11.04-.27.02-.11c.16-.82.45-1.59.87-2.28l.06-.1.08-.13A6.94 6.94 0 014.22.58l.04-.02.51-.2.06-.01.23-.08.06-.01.25-.07c.05 0 .09-.02.13-.03l.14-.03.13-.02L6 .07h.06L6.3.03h.14A3.85 3.85 0 017 0zm1.88 1.3L7.44 2.45a.7.7 0 01-.8.05l-.08-.05L5.12 1.3a6 6 0 00-2.96 2.16l.65 1.72a.7.7 0 01-.2.78L2.54 6 1 7.02v.2a5.96 5.96 0 001.14 3.29l1.83-.09a.7.7 0 01.68.43l.03.09.49 1.77a5.94 5.94 0 003.66 0l.49-1.77a.7.7 0 01.62-.51h.09l1.84.08A5.96 5.96 0 0013 7.02l-1.54-1.01a.7.7 0 01-.3-.75l.03-.08.65-1.72A6.01 6.01 0 008.88 1.3zM7.4 4.5l1.84 1.33c.24.18.35.5.25.79l-.7 2.16a.7.7 0 01-.66.48H5.86a.7.7 0 01-.66-.48l-.7-2.16a.7.7 0 01.25-.78L6.59 4.5a.7.7 0 01.82 0z"></path></g></svg>}
                                {lineUpsData.lineups.homeSubs[index]?.goal?.amt >1 && <span className='goalcount'>{lineUpsData.lineups.homeSubs[index].goal.amt}</span>}
                            </span> 
                            <span style={{marginLeft:'20px'}}>{lineUpsData.lineups.homeSubs[index]?.name}</span></>}
                        </div>
                        <div style={{width:'50%',display:'flex'}}>
                            {lineUpsData.lineups.awaySubs[index] && <><span class="subplayericon">
                                {lineUpsData.lineups.awaySubs[index]?.number}
                                {lineUpsData.lineups.awaySubs[index]?.sub && <svg className='subinicon' viewBox="0 0 14 14" fill="red" name="FootballSubIn"><path fill="#23df8c" d="M14 7A7 7 0 100 7a7 7 0 0014 0z"></path><path fill="#111111" d="M9 10.5V7h1.44a.25.25 0 00.19-.42L7.37 2.92a.5.5 0 00-.74 0L3.37 6.58a.25.25 0 00.19.42H5v3.5c0 .28.22.5.5.5h3a.5.5 0 00.5-.5z"></path></svg>}
                                <span className='subintime'>{lineUpsData.lineups.awaySubs[index]?.sub?.subTime}</span>
                                {lineUpsData.lineups.awaySubs[index]?.card && <svg className='yellowcard' xmlns="http://www.w3.org/2000/svg" width="11" height="14" name="FootballYellowCard"><rect width="10" height="14" x="2" rx="2" transform="translate(-1.68)" fill={lineUpsData.lineups.awaySubs[index].card==="FootballYellowCard"?"#FFCE00":lineUpsData.lineups.awaySubs[index].card==="FootballRedCard"?"#DF2357":""} fill-rule="evenodd"></rect></svg>}
                                {lineUpsData.lineups.awaySubs[index]?.goal && <svg className='footballgoal' xmlns="http://www.w3.org/2000/svg" width="15" height="14" name="FootballGoal"><g transform="translate(.63)" fill="none" fill-rule="evenodd"><circle cx="7" cy="7" r="6" fill="#FDFDFD"></circle><path fill="#222" d="M7 0h.28H7a6.8 6.8 0 01.56.02l.14.01.25.03h.02l.04.01.22.04.13.02.14.03.13.03.24.06.08.03.23.07.04.01.53.2.03.01c.95.42 1.8 1.03 2.47 1.8a5.96 5.96 0 01.62.82l.08.13.06.1c.42.7.72 1.49.87 2.32l.02.07a7.47 7.47 0 01.07 1.85 6.97 6.97 0 01-.94 2.9l-.03.05-.08.13.1-.18-.2.34-.03.04-.37.5-.04.03c-.12.16-.26.3-.4.45l-.05.05-.12.12-.4.35-.07.05-.19.15-.04.02v.01l-.25.17.25-.18a7.3 7.3 0 01-1.67.9l-.05.02-.22.08-.06.02a5.9 5.9 0 01-.37.1l-.16.04a6.95 6.95 0 01-3.11.01l-.06-.01-.15-.04-.09-.02-.03-.01a6.16 6.16 0 01-.24-.07l-.09-.03-.2-.07-.06-.02a7.96 7.96 0 01-.24-.1h-.03c-.5-.22-.96-.48-1.38-.79h-.01l-.04-.03-.2-.16.24.18a6.66 6.66 0 01-.82-.7l-.05-.04a6.47 6.47 0 01-.4-.45l-.04-.04A6.97 6.97 0 01.03 7.66a7.5 7.5 0 010-1.34l.02-.13.01-.11.04-.27.02-.11c.16-.82.45-1.59.87-2.28l.06-.1.08-.13A6.94 6.94 0 014.22.58l.04-.02.51-.2.06-.01.23-.08.06-.01.25-.07c.05 0 .09-.02.13-.03l.14-.03.13-.02L6 .07h.06L6.3.03h.14A3.85 3.85 0 017 0zm1.88 1.3L7.44 2.45a.7.7 0 01-.8.05l-.08-.05L5.12 1.3a6 6 0 00-2.96 2.16l.65 1.72a.7.7 0 01-.2.78L2.54 6 1 7.02v.2a5.96 5.96 0 001.14 3.29l1.83-.09a.7.7 0 01.68.43l.03.09.49 1.77a5.94 5.94 0 003.66 0l.49-1.77a.7.7 0 01.62-.51h.09l1.84.08A5.96 5.96 0 0013 7.02l-1.54-1.01a.7.7 0 01-.3-.75l.03-.08.65-1.72A6.01 6.01 0 008.88 1.3zM7.4 4.5l1.84 1.33c.24.18.35.5.25.79l-.7 2.16a.7.7 0 01-.66.48H5.86a.7.7 0 01-.66-.48l-.7-2.16a.7.7 0 01.25-.78L6.59 4.5a.7.7 0 01.82 0z"></path></g></svg>}
                                {lineUpsData.lineups.awaySubs[index]?.goal?.amt >1 && <span className='goalcount'>{lineUpsData.lineups.awaySubs[index].goal.amt}</span>}
                            </span>
                            <span style={{marginLeft:'20px'}}>{lineUpsData.lineups.awaySubs[index]?.name}</span></>}
                        </div>
                    </div>
                </div>
            ))}
        </div>
        </>
    )
    :''}
    </>
  )
}

export default LineUps