import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'production' ? 'https://football-tank.site/api' : 'http://localhost:4000/api';

const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    withCredentials: true,
  },
});

export default instance;